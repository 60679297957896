@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');

/* to change color from white is in getstatus */ 

body {
  margin: 0;
  font-family: 'Cinzel Decorative', cursive ;
  background-color:#111;
  padding: 0;
  /*  display: flex; idk if this works ---- This is why i couldnt get content to align */ 
  color: white;

}
p { 
  margin: 0px;
  padding: 0px;
} 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/*
.aboutall{
}
.wallleft{ 
} */ 
.about {
  color: white;  
}
/*
.wallright{
}
*/
.hovfont {
  font-family: 'Cinzel Decorative', cursive ;
}
.navlinkexternal { 
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: white;
  border: 4px solid white;
  padding: 8px;
  font-size: .5em;
  margin: 1px 1px;
  cursor: pointer;
  transition-duration: 0.8s;  
}

.navlinkexternal:hover {
  background-color: white;
  color:#111;
  border: 6px solid #111;
}
.statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
.statbutton {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 8px;
  font-size: 10px;
  margin: 1px 1px;
  cursor: pointer;
  transition-duration: 0.8s;
}
.statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
.button {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 18px;
  font-size: 20px;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;

}
.button:hover {
  background-color: #999;
  color:#111;
}
.buttonspecial { 
  font-family: 'Press Start 2P', cursive;
  background-color: #999;
  color: #111;
  border: 4px solid #111;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
}
.buttonspecial:hover {
  background-color: #111;
  color:#999;
}
.navlink{ 
  font-family: 'Press Start 2P', cursive;
  background-color: #999;
  color: #111;
  border: 4px solid #111;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
}
.navlink:hover{
  color: red;
}
.navbar {
  text-align: center; 
  font-size: 2em;
}
h1 { 
  font-size: 40px;
}
/* status area */ 
#heroreborn { 
  display: flex;
  
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}
#flexdeathleft{
}
#flexdeathright {
}
#numberdeathleft {
  font-family: 'Fira Code', monospace !important; /* lol no idea why this doesnt work */ 
}
#numberdeathright  {
  font-family: 'Fira Code', monospace !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#statusfunctions {
  border: 4px solid #999;
  padding: 10px;
}
#status {
}
#statustext { /* this is the main text output */ 
  font-size: 1em;
  font-family: 'Press Start 2P', cursive;

}

/* end status area */ 
/* hero player area */ 
#flex {
  display: flex;
}
/* force height 
.tall {
  height:200px;
}
*/
#heroplayer { 
  display: flex;
  text-align: left;
  align-items: left;
  justify-content: left;
  font-family: 'Fira Code', monospace;
}
#heroget { /*buttons inside heroplayer */ 
  background-color: #999;
  color: #111;
  padding: 10px;

}
#play {
  display: flex;
}
/* end hero player area */ 
.number { 
  display: flex;
}
#number { 
  display: flex; 
  
}
/* make emojis grey */ 
.grey {
  -webkit-filter: grayscale(100%);
   filter: grayscale(100%);
}


/* about page */
.about{
  background-color:#111;
  font-size: 2em;
}
.Game{
  display: flex;
}

/* special stuff */
#videogame{
  font-family: 'Press Start 2P', cursive;
}
#risevillain:hover {
  color: red;
}
#risehero:hover {
  color: white;
}

#red {
  color: red;
}
#wardiv {

}
#wartitle{
  font-size: 2em;
}
#warheader {
  background-color: #fffbf3; 
  color: #6d6d6d;  
}
#war {
  background-color: #fffbf3; 
  color: #6d6d6d;
  text-align: center;
  font-size: 1.6em;
  letter-spacing: .8px;
  font-weight: 400;

}
#rules {
  padding-left: 20px;
  text-align: left;
}
#start {
  color: black;
  background-color: #999;
  font-family: 'Press Start 2P', cursive;

}
/*
#footer{
  background-color: white;
  color: black;
}
*/
